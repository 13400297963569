import './index.css';
import App from './App';
import * as React from "react";
import * as ReactDOM from "react-dom";
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";

import Parking from './parking/index'
import ParkingAdmin from './parking/adminPage/index'
import RainbowGame from './game';

const router = createBrowserRouter([
  {
    path: "/",
    element:<App />,
  },
  {
    path: 'parking',
    element: <Parking/>,
  },
  {
    path: 'novarchie',
    element: <RainbowGame/>
  },
  {
    path:'parking/admin',
    element: <ParkingAdmin/>
  }
]);

ReactDOM.createRoot(document.getElementById("root")).render(
  <RouterProvider router={router} />
);
